html {
  font-family: Poppins;
  font-size: 14px;
}

.ant-message-notice-content {
  background: #393e50;
  color: #ebeced;
  font-weight: 600;
  font-size: 1.14rem;
  border-radius: 8px;
}

.ant-message-notice-content .ant-message-info .anticon {
  color: #ebeced;
}

/* .ant-switch {
  background-color: #e0e9ed;
}

.ant-switch-checked {
  background-color: #ff9150;
}

.ant-switch-small.ant-switch-checked {
  background-color: #393e50;
} */

.ant-table-filter-dropdown {
  border-radius: 12px;
}

.ant-popover-inner-content {
  padding: 8px 0;
}
.ant-popover-inner {
  border-radius: 12px;
  box-shadow: '0px 2px 16px -4px rgba(23, 30, 41, 0.08)';
  /* box-shadow: none; */
  /* border: 1px solid #edf4f6; */
}

.ant-popover-arrow {
  display: none;
}

.ant-spin {
  display: flex;
  justify-content: center;
  margin-top: 60px;
}
